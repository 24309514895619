import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Header from '../components/Header';

export default function Success() {
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  let query = useQuery();
  const [vehicle, setVehicle] = useState({ year: query.get('vehicleYear'), make: query.get('vehicleMake'), model: query.get('vehicleModel') });

  return (
    <div>
      <Header title={'Success'} />
      <div className="bg-[#FFFFFF] text-[#090F27] w-screen flex justify-center pb-20">
        <div className="w-10/12 sm:w-1/2 lg:w-1/3 text-left">
          <div className="mt-2 pb-5 text-center">
            <div className="flex place-content-center">
              <img className="mb-10 max-w-[150px] md:max-w-[250px]" src={require('../assets/kool2u-polar-bear-logo.png')} />
            </div>
            <h2 className="font-oswald font-black text-3xl text-center uppercase">Thank you {query.get('name') || ''}, </h2>
            <p className="font-raleway pt-5 text-center text-xl">
              We've received your quote request for your {vehicle.year !== 'null' ? vehicle.year : ''} {vehicle.make !== 'null' ? vehicle.make : ''}{' '}
              {vehicle.model !== 'null' ? vehicle.model : 'vehicle'}! ✅
            </p>

            <div class="font-oswald flex flex-col p-3 pt-6 sm:pt-3 sm:p-8 pb-8 bg-gray-50 border border-solid border-gray-300 mt-5 md:mt-10 shadow-sm">
              <h3 class="font-oswald text-center text-2xl font-bold uppercase">What to expect next</h3>
              <div class="flex flex-row mt-10">
                <div className="text-4xl text-[#010739] lg:pr-14 pr-10 font-bold">1</div>
                <div class="flex flex-col mx-auto lg:mx-0 md:mx-0">
                  <h4 class="text-left m-0 m-0 leading-0 font-bold text-xl mt-2 mb-1 leading-tight lg:pr-8 capitalize">We will contact you.</h4>
                  <p class="font-raleway text-lg leading-6 text-left text-gray-600">We’ll reach out ASAP via text to provide you with your free quote.</p>
                </div>
              </div>

              <div class="flex flex-row mt-10">
                <div className="text-4xl text-[#010739] lg:pr-14 pr-10 font-bold">2</div>
                <div class="flex flex-col mx-auto lg:mx-0 md:mx-0">
                  <h4 class="text-left m-0 leading-0 font-bold text-xl mt-2 mb-1 leading-tight lg:pr-8 capitalize">Set Appointment.</h4>
                  <p class="font-raleway text-lg leading-6 text-left text-gray-600">We'll work around your schedule to find a time to come see your vehicle.</p>
                </div>
              </div>
              <div class="flex flex-row mt-10">
                <div className="text-4xl text-[#010739] lg:pr-14 pr-10 font-bold">3</div>
                <div class="flex flex-col mx-auto lg:mx-0 md:mx-0">
                  <h4 class="text-left m-0 leading-0 font-bold text-xl mt-2 mb-1 leading-tight lg:pr-8">We Come To You.</h4>
                  <p class="font-raleway text-lg leading-6 text-left text-gray-600">
                    At your home or at your workplace, we come to you. While we work, you can watch us or we will send you updates via text messages.{' '}
                  </p>
                </div>
              </div>
            </div>
            <div className="block lg:flex md:pt-9">
              <div className="inline-block w-full md:w-full lg:w-1/2 lg:pr-2">
                <a
                  href="tel:2103745727"
                  class="hover:text-gray-200 flex whitespace-nowrap justify-center items-center md:justify-center md:inline-block !text-center bg-[#010739] text-white text-lg font-semibold px-5 rounded disabled:cursor-not-allowed w-full py-3 sm:py-2 lg:py-2 text-md sm:text-sm mt-4 sm:mt-2 sm:mb-4 lg:mb-2"
                >
                  <div>
                    <svg
                      fill="#FFFFFF"
                      className="inline relative"
                      width="30pt"
                      height="30pt"
                      version="1.1"
                      viewBox="0 0 752 752"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="m464.9 557.31c-2.875 0-5.7891-0.21094-8.7383-0.64844-54.145-7.9961-116.73-49.059-167.42-109.84-51.848-62.18-79.277-132.09-73.367-187.03 1.6562-15.445 8.625-28.23 20.699-38.008l17.875-14.477c24.301-19.68 60.082-15.918 79.766 8.3828l21.129 26.094c15.578 19.242 13.629 47.566-4.4453 64.484-10.332 9.6758-12.332 34.996-3.6289 45.922l46.68 58.598c8.7461 10.973 34.625 14.527 46.145 6.332 20.363-14.477 48.633-10.605 64.359 8.8086l20.852 25.754c19.68 24.301 15.918 60.082-8.3828 79.758l-16.285 13.188c-10.344 8.375-22.387 12.684-35.234 12.684zm-175.3-339.96c-7.5273 0-15.102 2.4805-21.395 7.5742l-17.875 14.477c-7.3555 5.9531-11.418 13.414-12.43 22.82-11.746 109.21 124.08 257.63 221.57 272.03 9.9531 1.4648 18.582-0.89844 26.398-7.2305l16.285-13.188c14.59-11.816 16.848-33.297 5.0312-47.887l-20.852-25.754c-8.2109-10.145-22.977-12.164-33.609-4.6016-21.66 15.402-60.523 10.008-77.004-10.676l-46.68-58.598c-16.074-20.172-13.109-58.82 5.8672-76.59 9.4375-8.832 10.457-23.625 2.3203-33.676l-21.129-26.094c-6.7188-8.2969-16.57-12.605-26.496-12.605z" />
                    </svg>
                  </div>
                  <div className='font-oswald'>(210) 374-5727</div>
                </a>
              </div>
              <div className="inline-block w-full lg:w-1/2 lg:pl-2">
                <button
                  onClick={() => (window.location.href = 'https://www.kool2u.com/faq')}
                  class="flex whitespace-nowrap justify-center items-center md:justify-center md:inline-block !text-center bg-[#010739] text-white text-lg font-semibold px-5 rounded disabled:cursor-not-allowed w-full py-3 sm:py-2 lg:py-2 text-md sm:text-sm mt-4 sm:mt-2 sm:mb-4 lg:mb-2"
                >
                  <div>
                    <svg
                      fill="#FFFFFF"
                      className="inline relative w-[32px] md:w-[28px] md:-left-2"
                      width="30pt"
                      height="30pt"
                      version="1.1"
                      viewBox="0 0 752 752"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <defs>
                        <clipPath id="a">
                          <path d="m139.21 139.21h473.58v473.58h-473.58z" />
                        </clipPath>
                      </defs>
                      <g clip-path="url(#a)">
                        <path d="m612.79 376c0 130.78-106.01 236.79-236.79 236.79-130.77 0-236.79-106.02-236.79-236.79s106.02-236.79 236.79-236.79c130.78 0 236.79 106.01 236.79 236.79zm-20.395 0c0-119.52-96.879-216.39-216.4-216.39-119.51 0-216.39 96.883-216.39 216.39 0 119.52 96.887 216.4 216.39 216.4 119.52 0 216.4-96.879 216.4-216.4zm-216.09 135.31c-12.453 0-22.551-10.094-22.551-22.551 0-12.453 10.098-22.551 22.551-22.551 12.457 0 22.555 10.098 22.555 22.551 0 12.457-10.098 22.551-22.555 22.551zm67.656-202.96c0 33.52-24.383 61.348-56.379 66.719v56.621h-22.551v-78.238h11.273c24.91 0 45.105-20.195 45.105-45.102 0-24.914-20.195-45.109-45.105-45.109-24.906 0-45.102 20.195-45.102 45.105v11.277h-22.551v-11.277c0-37.363 30.289-67.656 67.652-67.656 37.367 0 67.656 30.293 67.656 67.66z" />
                      </g>
                    </svg>
                  </div>
                  <div className='font-oswald'>FAQ's</div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

export default function Header(props) {
  const navigate = useNavigate();

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let query = useQuery();

  return (
    <header class="bg-[#010739] h-auto sticky top-0 border-b border-[#737373] pt-1">
      <div class="flex justify-between items-center mx-auto">
        <div class="flex items-center pl-5">
          {props.showBack ? (
            <svg
              onClick={() =>
                navigate(
                  `/get-a-quote/services?vehicleYear=${query.get('vehicleYear')}&vehicleMake=${query.get('vehicleMake')}&vehicleModel=${query.get(
                    'vehicleModel'
                  )}`
                )
              }
              fill="#FFFFFF"
              className="cursor-pointer inline relative w-[40px]"
              xmlns="http://www.w3.org/2000/svg"
              version="1.1"
              viewBox="0 0 752 752"
            >
              <path d="m549.05 361.79h-312.04l101.54-102.48c2.8047-2.6445 4.418-6.3125 4.4648-10.164 0.050782-3.8555-1.4688-7.5625-4.207-10.277-2.7383-2.7109-6.4609-4.1953-10.312-4.1094-3.8516 0.085937-7.5078 1.7344-10.121 4.5625l-125.5 126.68c-5.4766 5.5352-5.4766 14.449 0 19.984l125.5 126.68c2.6133 2.832 6.2695 4.4805 10.121 4.5664 3.8516 0.085937 7.5742-1.3984 10.312-4.1133 2.7383-2.7109 4.2578-6.418 4.207-10.273-0.046874-3.8555-1.6602-7.5234-4.4648-10.164l-101.54-102.48h312.04c7.8477 0 14.207-6.3594 14.207-14.207 0-7.8438-6.3594-14.207-14.207-14.207z" />
            </svg>
          ) : (
            <div className="w-10"></div>
          )}
        </div>
        <div>
          <p class="font-oswald font-bold tracking-widest uppercase text-[#FFFFFF]">{props.title}</p>
        </div>
        <div>
          <img
            className="h-10 pr-5 cursor-pointer"
            src={require('../assets/k2u-logo.webp')}
            onClick={() => (window.location.href = 'https://www.kool2u.com/')}
          />
        </div>
      </div>
      <div class="flex justify-center content-center mb-1"></div>
    </header>
  );
}

import React, { createContext, useReducer, useContext } from 'react';

export const QuoteContext = createContext();

// Initial state
const INITIAL_STATE = {
  vehicleYear: '',
  vehicleMake: '',
  vehicleModel: '',
  services: []
};

// Actions
export const UPDATE_FORM = 'UPDATE_FORM';

// Action creators
export function updateForm(data) {
  console.log(data);
  return { type: UPDATE_FORM, data };
}

// Reducer
export function quoteReducer(state = INITIAL_STATE, action) {
  console.log(state);
  switch (action.type) {
    case UPDATE_FORM:
      return action.data;
    default:
      return state;
  }
}

function QuoteProvider(props) {
  const [form, dispatch] = useReducer(quoteReducer, INITIAL_STATE);

  const quoteData = { form, dispatch };

  return <QuoteContext.Provider value={quoteData} {...props} />;
}

function useQuoteContext() {
  return useContext(QuoteContext);
}

export { QuoteProvider, useQuoteContext };

import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import { QuoteProvider } from './QuoteContext';
import CustomerInfo from './Pages/CustomerInfo';
import Services from './Pages/Services';
import Success from './Pages/Success';

export default function App(props) {
  const [form, setForm] = useState({
    vehicleYear: '',
    vehicleMake: '',
    vehicleModel: '',
    services: []
  });

  return (
    <QuoteProvider>
      <div>
        <Router>
          <Routes>
            <Route exact path="/get-a-quote/services" element={<Services />} />
            <Route exact path="/get-a-quote/add-info" element={<CustomerInfo />} />
            <Route exact path="/get-a-quote/success" element={<Success />} />
          </Routes>
        </Router>
      </div>
    </QuoteProvider>
  );
}

import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';
import Header from '../components/Header';
import { useQuoteContext, updateForm } from '../QuoteContext';

export default function CustomerInfo(props) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const { form, dispatch } = useQuoteContext();

  const nameRef = React.useRef(null);
  const zipCodeRef = React.useRef(null);
  const timeframeRef = React.useRef(null);
  const phoneRef = React.useRef(null);

  const navigate = useNavigate();

  useEffect(() => {
    if (form.services.length === 0)
      return navigate(
        `/get-a-quote/services?vehicleYear=${query.get('vehicleYear')}&vehicleMake=${query.get('vehicleMake')}&vehicleModel=${query.get('vehicleModel')}`
      );
  }, []);

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  function formatPhoneNumber(value) {
    if (!value) return value;
    const phoneNumber = value.replace(/[^\d]/g, '');
    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength < 4) return phoneNumber;
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 9)}`;
  }

  function phoneNumberFormatter() {
    const inputField = document.getElementById('phone-number');
    const formattedInputValue = formatPhoneNumber(inputField.value);
    inputField.value = formattedInputValue;
  }

  function handleErrors(response) {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response;
  }

  async function submitQuoteRequest(form) {
    setLoading(true);
    const response = await fetch('https://hooks.zapier.com/hooks/catch/13158090/be130yj/', {
      method: 'POST',
      headers: {
        Accept: 'application/json'
      },
      body: JSON.stringify({
        form
      })
    })
      .then(handleErrors)
      .then((response) => response)
      .catch((error) => error);

    return response;
  }

  let query = useQuery();

  return (
    <div>
      <Header title={'Your Info'} showBack={true} />
      <div className="bg-[#FFFFFF] text-[#090F27] w-screen pb-20">
        <div className="text-left">
          <div className="mt-3 pb-5">
            <div className="flex justify-center">
              <div className="w-10/12 sm:w-1/2 lg:w-1/3">
                <h2 className="font-oswald font-bold text-2xl">Let's wrap up your free quote</h2>
                {query.get('vehicleMake') !== "null" && query.get('vehicleMake') !== null ?
                  <p className="pt-2">
                  {' '}
                  For your {query.get('vehicleMake') || null} {query.get('vehicleModel') || null}
                </p>
                : 
                <p className="pt-2">
                
                </p>
                }
              </div>
            </div>
            <form
              onSubmit={async (e) => {
                e.preventDefault();
                await submitQuoteRequest(form).then((res) => {
                  if (res.ok) {
                    setLoading(false);
                    console.log('successs');
                    return navigate(
                      `/get-a-quote/success?vehicleYear=${form.vehicleYear}&vehicleMake=${form.vehicleMake}&vehicleModel=${form.vehicleModel}&name=${
                        form.fullName.match(/[^\s]+/g)[0]
                      }`
                    );
                  } else {
                    setLoading(false);
                    setError(true);
                    console.log('ERROR');
                  }
                });
              }}
              className="pt-5 w-full justify-center flex font-raleway"
            >
              <div class="form-group mb-6 w-10/12 sm:w-1/2 lg:w-1/3">
                <div class="w-full flex justify-start items-center relative py-3">
                  <svg
                    fill="#010739"
                    class="absolute mr-2 ml-1 w-10"
                    width="25pt"
                    height="25pt"
                    version="1.1"
                    viewBox="0 0 752 752"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="m280.44 539.06c30.805 10.211 63.113 15.125 95.559 14.531 67.516 0 146.36-18.152 146.36-69.289-0.046875-29.352-8.9102-58.008-25.434-82.262-16.527-24.254-39.957-42.984-67.254-53.77 18.07-14.84 29.191-36.504 30.715-59.836 1.5234-23.336-6.6836-46.262-22.672-63.328-15.988-17.066-38.328-26.746-61.715-26.746-23.383 0-45.723 9.6797-61.711 26.746-15.988 17.066-24.195 39.992-22.672 63.328 1.5234 23.332 12.645 44.996 30.715 59.836-27.297 10.785-50.727 29.516-67.254 53.77-16.527 24.254-25.387 52.91-25.434 82.262 0 16.441 8.8125 39.297 50.797 54.758zm40.594-256.09c0-14.578 5.793-28.559 16.102-38.867 10.305-10.309 24.285-16.098 38.863-16.098s28.559 5.7891 38.867 16.098c10.309 10.309 16.098 24.289 16.098 38.867s-5.7891 28.559-16.098 38.867c-10.309 10.305-24.289 16.098-38.867 16.098-14.57-0.019531-28.543-5.8125-38.844-16.117-10.305-10.305-16.102-24.277-16.121-38.848zm54.965 84.562c30.957 0.042969 60.633 12.359 82.52 34.246 21.891 21.891 34.203 51.566 34.242 82.523 0 21.887-52.383 39.691-116.76 39.691s-116.76-17.805-116.76-39.691h0.003907c0.039062-30.957 12.352-60.633 34.242-82.523 21.887-21.887 51.562-34.203 82.516-34.246z" />{' '}
                  </svg>
                  <input
                    onBlur={() =>
                      dispatch(
                        updateForm({
                          ...form,
                          fullName: nameRef.current.value
                        })
                      )
                    }
                    autoFocus
                    ref={nameRef}
                    type="text"
                    required
                    placeholder="Full Name *"
                    class="border border-gray-400 rounded-lg p-4 !pl-12 w-full placeholder:text-lg text-[16px] focus:border-[#010739] focus:ring-[#010739] focus:shadow-[#010739]"
                  />
                </div>

                <div class="w-full flex justify-start items-center relative py-3">
                  <svg
                    fill="#010739"
                    class="absolute mr-2 ml-1 w-10"
                    width="25pt"
                    height="25pt"
                    version="1.1"
                    viewBox="0 0 752 752"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="m376 181.83c-75.977 0-137.34 64.062-137.34 142.07 0 28.133 8.4727 50.523 21.605 71.777l103.6 167.68h0.003907c2.5781 4.2344 7.1758 6.8203 12.133 6.8203 4.9609 0 9.5586-2.5859 12.137-6.8203l103.6-167.68c13.133-21.254 21.605-43.645 21.605-71.777 0-78.012-61.363-142.07-137.34-142.07zm0 28.414c60.031 0 108.92 50.434 108.92 113.66 0 22.77-5.9688 38.227-17.465 56.828l-91.457 148-91.457-148c-11.496-18.605-17.465-34.059-17.465-56.828 0-63.227 48.895-113.66 108.92-113.66zm0 37.887c-39.062 0-71.039 31.973-71.039 71.035s31.973 71.039 71.039 71.039 71.039-31.973 71.039-71.039c0-39.062-31.973-71.035-71.039-71.035zm0 28.414c23.707 0 42.621 18.914 42.621 42.621 0 23.707-18.914 42.621-42.621 42.621s-42.621-18.914-42.621-42.621c0-23.707 18.914-42.621 42.621-42.621z" />
                  </svg>
                  <input
                    ref={zipCodeRef}
                    onBlur={() =>{
                      // var isValidZip = /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(zipCodeRef.current.value);
                      // if (!isValidZip) return false;
                      return dispatch(
                        updateForm({
                          ...form,
                          zipCode: zipCodeRef.current.value
                        })
                      )
                    }}
                    pattern="(^\d{5}$)|(^\d{9}$)|(^\d{5}-\d{4}$)"
                    onKeyUp={(e)=> e.target.value=e.target.value.replace(/[^\d]/,'')}
                    onInvalid={(e)=> e.target.setCustomValidity('Please enter a valid zip code')}
                    onInput={(e)=> e.target.setCustomValidity("")}
                    id="zip"
                    name="zip"
                    type="text"
                    required
                    placeholder="Zip Code *"
                    class="peer border border-gray-400 rounded-lg p-4 !pl-12 w-full placeholder:text-lg placeholder:text-gray-400 text-[16px] focus:border-[#010739] focus:ring-[#010739] focus:shadow-[#010739]"
                  />
                  {/* <p class="inline invisible peer-invalid:visible text-red-700 font-light">
                    Please enter a valid zip code
                </p> */}
                </div>
                <div class="w-full flex justify-start items-center relative py-3">
                  <svg
                    fill="#010739"
                    class="absolute mr-2 ml-1 w-10"
                    width="20pt"
                    height="20pt"
                    version="1.1"
                    viewBox="0 0 752 752"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="m514.13 203.34h-44.398v-24.664c0-5.2852-2.8203-10.172-7.3984-12.816s-10.223-2.6445-14.801 0-7.3984 7.5312-7.3984 12.816v24.664h-128.26v-24.664c0-5.2852-2.8203-10.172-7.3984-12.816-4.582-2.6445-10.223-2.6445-14.801 0s-7.3984 7.5312-7.3984 12.816v24.664h-44.398c-19.621 0.023437-38.43 7.8242-52.301 21.699-13.875 13.871-21.676 32.68-21.699 52.301v236.79c0.023438 19.617 7.8242 38.43 21.699 52.301 13.871 13.871 32.68 21.676 52.301 21.695h276.25c19.617-0.023438 38.422-7.8281 52.297-21.699 13.871-13.875 21.676-32.68 21.699-52.297v-236.79c-0.023438-19.617-7.8281-38.426-21.699-52.297-13.875-13.871-32.68-21.676-52.297-21.703zm-320.65 74c0.007813-11.773 4.6914-23.059 13.016-31.383 8.3242-8.3242 19.609-13.008 31.383-13.016h44.398v24.664c0 5.2891 2.8203 10.172 7.3984 12.816s10.219 2.6445 14.801 0c4.5781-2.6445 7.3984-7.5273 7.3984-12.816v-24.664h128.26v24.664c0 5.2891 2.8203 10.172 7.3984 12.816s10.223 2.6445 14.801 0 7.3984-7.5273 7.3984-12.816v-24.664h44.398c11.77 0.015625 23.055 4.6992 31.379 13.02 8.3203 8.3242 13.004 19.609 13.02 31.379v44.398h-365.05zm320.65 281.19h-276.25c-11.773-0.011719-23.059-4.6914-31.383-13.016-8.3242-8.3242-13.008-19.609-13.016-31.383v-162.79h365.05v162.79c-0.015625 11.77-4.6992 23.055-13.02 31.379-8.3242 8.3203-19.609 13.004-31.379 13.02z" />
                  </svg>
                  <select
                    onBlur={() =>
                      dispatch(
                        updateForm({
                          ...form,
                          timeframe: timeframeRef.current.value
                        })
                      )
                    }
                    ref={timeframeRef}
                    required
                    class="form-select appearance-noneblock w-full border border-gray-400 rounded-lg p-4 !pl-12 text-lg  px-4 py-4 font-normal bg-white bg-clip-padding bg-no-repeat text-black invalid:text-gray-400
                            transition
                            ease-in-out
                            m-0
                            focus:text-gray-700 focus:bg-white focus:outline-none focus:border-[#010739] focus:ring-[#010739] focus:shadow-[#010739]"
                    aria-label="Default select example"
                  >
                    <option selected value="">
                      When do you need service? *
                    </option>
                    {/* <option disabled value="Today" className="">
                      Today (fully booked)
                    </option> */}
                    <option value="Tomorrow">Tomorrow</option>
                    <option value="Sometime this week">Sometime this week</option>
                    <option value="Sometime this month">Sometime this month</option>
                  </select>
                </div>
                <div class="w-full flex justify-start items-center relative py-3">
                  <svg
                    fill="#010739"
                    class="absolute mr-2 ml-1 w-10"
                    width="30pt"
                    height="30pt"
                    version="1.1"
                    viewBox="0 0 752 752"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="m464.9 557.31c-2.875 0-5.7891-0.21094-8.7383-0.64844-54.145-7.9961-116.73-49.059-167.42-109.84-51.848-62.18-79.277-132.09-73.367-187.03 1.6562-15.445 8.625-28.23 20.699-38.008l17.875-14.477c24.301-19.68 60.082-15.918 79.766 8.3828l21.129 26.094c15.578 19.242 13.629 47.566-4.4453 64.484-10.332 9.6758-12.332 34.996-3.6289 45.922l46.68 58.598c8.7461 10.973 34.625 14.527 46.145 6.332 20.363-14.477 48.633-10.605 64.359 8.8086l20.852 25.754c19.68 24.301 15.918 60.082-8.3828 79.758l-16.285 13.188c-10.344 8.375-22.387 12.684-35.234 12.684zm-175.3-339.96c-7.5273 0-15.102 2.4805-21.395 7.5742l-17.875 14.477c-7.3555 5.9531-11.418 13.414-12.43 22.82-11.746 109.21 124.08 257.63 221.57 272.03 9.9531 1.4648 18.582-0.89844 26.398-7.2305l16.285-13.188c14.59-11.816 16.848-33.297 5.0312-47.887l-20.852-25.754c-8.2109-10.145-22.977-12.164-33.609-4.6016-21.66 15.402-60.523 10.008-77.004-10.676l-46.68-58.598c-16.074-20.172-13.109-58.82 5.8672-76.59 9.4375-8.832 10.457-23.625 2.3203-33.676l-21.129-26.094c-6.7188-8.2969-16.57-12.605-26.496-12.605z" />
                  </svg>
                  <input
                    onBlur={() =>
                      dispatch(
                        updateForm({
                          ...form,
                          phone: phoneRef.current.value
                        })
                      )
                    }
                    required={true}
                    ref={phoneRef}
                    pattern="^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$"
                    //onKeyUp={(e)=> e.target.value=e.target.value.replace(/[^\d]/,'')}
                    onInvalid={(e)=> e.target.setCustomValidity('Please enter a valid phone number')}
                    onInput={(e)=> e.target.setCustomValidity("")}
                    onKeyDown={() => phoneNumberFormatter()}
                    type="tel"
                    id="phone-number"
                    name="phone"
                    placeholder="Phone Number *"
                    class="border border-gray-400 rounded-lg p-4 !pl-12 w-full placeholder:text-lg placeholder:text-gray-400 text-[16px] focus:border-[#010739] focus:ring-[#010739] focus:shadow-[#010739]"
                  />
                </div>
              </div>
              <footer class="w-screen bg-[#F8F8F8] border-t border-[#737373] py-2 z-50 fixed bottom-0 h-20 flex flex-row justify-center">
                {loading ? (
                  <button
                    disabled={loading}
                    type="submit"
                    class="w-10/12 sm:w-1/2 lg:w-1/3 !bg-[#010739] rounded-md text-white uppercase font-semibold text-lg tracking-wide my-1"
                    tabindex="0"
                    //type="submit"
                  >
                    <div class="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  </button>
                ) : error ? (
                  <h1 className="self-center">
                    <p className="text-red-500 inline-block font-bold">ERROR: </p> Please call{' '}
                    <a className="underline" href="tel:18443995665">
                      1-844-399-KOOL
                    </a>{' '}
                    for your free quote
                  </h1>
                ) : (
                  <button
                    type="submit"
                    class="font-oswald w-10/12 sm:w-1/2 lg:w-1/3 !bg-[#010739] rounded-md text-white uppercase font-semibold text-lg tracking-wide my-1"
                    tabindex="0"
                    //type="submit"
                  >
                    Get My Free Quote
                  </button>
                )}
              </footer>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
